import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import CustomContent from '../components/custom-content';
import Head from '../components/head';
import Layout from '../components/layout';

const Legal = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulCustomPage(internalName: {eq: "Legal Page"}) {
        header
        internalName
        customContent {
          json
        }
      }
    }
  `)

  return (
    <Layout>
      <Head title={'Privacy'} />
      <CustomContent data={data.contentfulCustomPage} />
    </Layout>
  )
}

export default Legal;
